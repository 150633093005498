import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { log } from 'console';
import { FileUploader } from 'ng2-file-upload';
import * as XLSX from 'xlsx';

interface MatchingFields {
  field: string;
  match: FormControl;
}
@Component({
  selector: 'sc-import-file',
  templateUrl: './import-file.component.html',
  styleUrls: ['./import-file.component.scss']
})
export class ImportFileComponent implements OnInit {
  arrayBuffer: any;
  uploader: FileUploader = new FileUploader({ url: null });
  file: File;
  title: string;
  parameters: string[] = [];
  parsedData: any[] = [];

  requiredFields: string[] = []; //Input / Sent from class
  matchingFields: MatchingFields[] = [];

  hasBaseDropZoneOver: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<ImportFileComponent>,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    for (let reqField of this.requiredFields) {
      this.matchingFields.push({
        field: reqField,
        match: new FormControl(null, Validators.required)
      });
    }
  }
  fileDropped(ev) {
    if (ev[0]) {
      this.file = ev[0];
      this.readFile();
    }
  }

  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  fileFromClick(event) {
    this.file = event.target.files[0];
    this.readFile();
  }

  readFile() {
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);

      var subArr = data.subarray(0, 4);
      var header = '';
      for (var i = 0; i < subArr.length; i++) {
        header += subArr[i].toString(16);
      }

      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join('');
      var workbook = XLSX.read(bstr, { type: 'binary' });

      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];

      this.parameters = this.get_header_row(worksheet);
      this.parsedData = XLSX.utils.sheet_to_json(worksheet, {
        raw: true,
        blankrows: true
      });
      //this.parameters = Object.keys(this.parsedData[0]);

      this.checkFields();
    };
    fileReader.readAsArrayBuffer(this.file);
  }
  get_header_row(sheet) {
    var headers = [];
    var range = XLSX.utils.decode_range(sheet['!ref']);
    var C,
      R = range.s.r; /* start in the first row */
    /* walk every column in the range */
    for (C = range.s.c; C <= range.e.c; ++C) {
      var cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })]; /* find the cell in the first row */

      var hdr = 'UNKNOWN ' + C; // <-- replace with your desired default
      if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);

      headers.push(hdr);
    }
    return headers;
  }
  checkFields() {
    this.matchingFields.map((reqField) => {
      let match = this.parameters.filter((param) => param.toLowerCase().includes(reqField.field.toLowerCase()))[0];
      if (match) {
        reqField.match.patchValue(match);
      }
      return reqField;
    });
  }

  import() {
    let invalidFields = this.matchingFields.filter((field) => field.match.invalid).length;
    if (invalidFields) return;

    //Creating a new array with the matching fields
    let returnData = [];

    if (this.matchingFields.length) {
      for (let data of this.parsedData) {
        returnData.push({});
        for (let match of this.matchingFields) {
          returnData[returnData.length - 1][match.field] = data[match.match.value];
        }
      }
    } else {
      returnData = this.parsedData;
    }

    if (!returnData.length) {
      this.snackBar.open(this.translate.instant('INVALID_ROWS_IN_FILE'), this.translate.instant('CLOSE'));
    } else {
      this.dialogRef.close(returnData);
    }
  }
}
