import { Component, computed, inject, input, viewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormControl, FormControlDirective } from '@angular/forms';

@Component({
  template: ''
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class FormControlValueAccessorConnector implements ControlValueAccessor {
  readonly formControl = input<FormControl | undefined>();
  readonly formControlDirective = viewChild<FormControlDirective | undefined>('');
  readonly formControlName = input<string>('');

  private readonly controlContainer = inject(ControlContainer);
  private readonly valueAccessor = computed(() => {
    return this.formControlDirective()?.valueAccessor;
  });

  get control(): FormControl {
    return this.formControl() || (this.controlContainer.control?.get(this.formControlName()) as FormControl);
  }

  registerOnChange(fn: unknown): void {
    this.valueAccessor()?.registerOnChange(fn);
  }

  registerOnTouched(fn: unknown): void {
    this.valueAccessor()?.registerOnTouched(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    const valueAccessor = this.valueAccessor();

    if (valueAccessor?.setDisabledState) {
      valueAccessor.setDisabledState(isDisabled);
    }
  }

  writeValue(obj: unknown): void {
    this.valueAccessor()?.writeValue(obj);
  }
}
