import { Injectable } from '@angular/core';
import { FormArray, FormGroup, ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { findFormControls } from '@smartcharge/shared/index';
import { SmoothViewportScroller } from '@smartcharge/shared/services/smooth-viewport-scroller.service';
import { snakeCase } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  constructor(
    private readonly translateService: TranslateService,
    private readonly viewportScroller: SmoothViewportScroller
  ) {}

  getErrorMessage(errors: ValidationErrors, customErrors?: Record<string, string>): string {
    const [code, details] = Object.entries(errors)[0];
    let key;
    let params;

    if (customErrors && code in customErrors) {
      key = customErrors[code];
      params = { details };
    } else {
      switch (code) {
        case 'min':
          key = 'VALIDATION_MIN';
          params = { min: details.min };
          break;

        case 'max':
          key = 'VALIDATION_MAX';
          params = { max: details.max };
          break;

        case 'backend':
          key = 'VALIDATION_REJECTED';
          params = { reason: details };
          break;

        case 'notUnique':
          key = 'VALIDATION_NOT_UNIQUE';
          break;

        case 'invalid':
          key = 'VALIDATION_INVALID';
          break;

        default:
          key = `VALIDATION.${snakeCase(code).toUpperCase()}`;
      }
    }

    return this.translateService.instant(key, params || details);
  }

  scrollToFirstInvalidField(form: FormGroup | FormArray): void {
    const invalidControls = findFormControls(form, 'invalid');

    if (invalidControls.length) {
      this.viewportScroller.scrollToElement((invalidControls[0] as any).nativeElement, 80);
    }
  }
}
