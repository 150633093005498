import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { map, Observable, of } from 'rxjs';

import { Address, GeocoderResponse, GeolocationService } from '../../features/geolocation';

@Injectable({
  providedIn: 'root'
})
export class AddressValidator implements AsyncValidator {
  constructor(private readonly geolocationService: GeolocationService) {}

  createValidate(
    addressFields: Partial<Record<keyof Address, string>>,
    callbackFn?: (control: AbstractControl, geocoderResponse: GeocoderResponse) => void
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.parent) {
        return of(null);
      }

      const address = Object.entries(addressFields).reduce((acc, [key, value]) => {
        return { ...acc, [key]: control.parent?.get(value)?.value };
      }, {}) as Address;

      return this.geolocationService.validateAddress(address).pipe(
        map((result) => {
          if (result.results[0]?.types.includes('premise') || result.results[0]?.types.includes('subpremise')) {
            if (typeof callbackFn === 'function') {
              callbackFn(control, result);
            }

            return null;
          }

          return { invalidAddress: true };
        })
      );
    };
  }

  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    throw new Error('Method not implemented.');
  }
}
