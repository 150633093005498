import { ChargingBoxModel } from './types';

const formatKW = new Intl.NumberFormat('en-US', {
  minimumIntegerDigits: 1,
  minimumFractionDigits: 0,
  maximumFractionDigits: 1
});

export function formatBoxModelDescription(model: ChargingBoxModel): string {
  let kW;
  let segments = [];

  if (model.Amp && model.Volts && model.Phase) {
    kW = formatKW.format((model.Amp * model.Volts * Math.sqrt(model.Phase)) / 1000);
  }

  if (model.Amp) {
    segments.push(`${model.Amp}A`);
  }

  if (model.Volts) {
    segments.push(`${model.Volts}V`);
  }

  if (model.Phase) {
    segments.push(`φ${model.Phase}`);
  }

  segments = [segments.join(' ')];

  if (kW) {
    segments.unshift(`${kW} kW`);
  }

  return segments.join(' – ');
}

//{{ model.kW | number: '1.0-1' }} kW - {{ model.Amp }}A {{ model.Volts }}V φ{{ model.Phase }}
