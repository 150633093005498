import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const validUrl: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  try {
    /** Try to parse the string as a URL. If value is not a correct URL, exception will be thrown. */
    new URL(control.value);

    return null;
  } catch (_error) {
    return { invalidUrl: true };
  }
};
