<form [formGroup]="addFirmwareForm" (ngSubmit)="handleFormSubmit()" #formRef="ngForm">
  <h2 mat-dialog-title>{{ 'ADD_FIRMWARE' | translate }}</h2>

  <mat-dialog-content>
    <div class="input-row mb-2">
      <input type="file" (change)="handleFile($event)" class="hidden" #fileUpload />
      <button mat-stroked-button (click)="fileUpload.click()" type="button">
        <mat-icon>upload_file</mat-icon>
        <span
          [ngClass]="{
            'text-red-400': formRef.submitted && fileToUpload === null
          }">
          {{ 'UPLOAD_FIRMWARE_FILE' | translate }}
        </span>
      </button>
      <p *ngIf="fileToUpload !== null" class="!mt-0">{{ fileToUpload.name }}</p>
      <mat-error *ngIf="formRef.submitted && fileToUpload === null">
        {{ 'REQUIRED' | translate }}
      </mat-error>
    </div>

    <div class="input-row">
      <mat-form-field floatLabel="auto" class="w-full">
        <mat-label>{{ 'Firmware version' | translate }}</mat-label>
        <input matInput type="text" formControlName="FirmwareVersion" />
        <mat-error>{{ 'REQUIRED' | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div class="input-row">
      <mat-form-field floatLabel="auto" class="w-full">
        <mat-label>
          {{ 'Minimum required firmware version' | translate }}
        </mat-label>
        <input matInput type="text" formControlName="MinFirmwareVersionRequired" />
        <mat-error>{{ 'REQUIRED' | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div class="input-row">
      <mat-form-field floatLabel="auto" class="w-full">
        <mat-label>{{ 'Release date' | translate }}</mat-label>
        <input matInput [matDatepicker]="datePicker" (click)="datePicker.open()" formControlName="ReleaseDate" />
        <button
          matSuffix
          mat-icon-button
          *ngIf="addFirmwareForm.controls.ReleaseDate.value"
          (click)="addFirmwareForm.controls.ReleaseDate.reset()">
          <mat-icon>close</mat-icon>
        </button>
        <mat-datepicker #datePicker></mat-datepicker>
      </mat-form-field>
    </div>
    @if (!hideIsLatestFirmware) {
      <div>
        <mat-checkbox formControlName="IsLatestFirmware">
          {{ 'LATEST_FIRMWARE' | translate }}
        </mat-checkbox>
      </div>
    }
    <div>
      <mat-checkbox formControlName="MustRebootWhenDone">
        {{ 'MUST_REBOOT_WHEN_DONE' | translate }}
      </mat-checkbox>
    </div>
    <div>
      <mat-checkbox formControlName="UseManufacturersCertificate">
        {{ 'USE_MANUFACTURERS_CERTIFICATE' | translate }}
      </mat-checkbox>
      <mat-icon class="align-middle ml-2" matTooltip="{{ 'MANUFACTURERS_CERTIFICATE_HELP' | translate }}">
        help_outline
      </mat-icon>
    </div>

    <div class="input-row">
      <mat-form-field class="w-full">
        <mat-label>{{ 'SIGNING_CERTIFICATE' | translate }}</mat-label>
        <textarea matInput rows="4" formControlName="SigningCertificate"></textarea>
        <button
          mat-icon-button
          matSuffix
          class="input-helper"
          type="button"
          matTooltip="{{ 'SIGNING_CERTIFICATE_HELP' | translate }}">
          <mat-icon>help_outline</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="input-row">
      <mat-form-field class="w-full">
        <mat-label>{{ 'SIGNATURE' | translate }}</mat-label>
        <textarea matInput rows="4" formControlName="Signature"></textarea>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button color="primary" type="submit" [disabled]="isAdding">
      <mat-spinner *ngIf="isAdding" class="button-spinner"></mat-spinner>
      <span *ngIf="!isAdding">{{ 'ADD_FIRMWARE' | translate }}</span>
    </button>
    <button mat-raised-button (click)="this.dialogRef.close()" type="button">
      {{ 'CANCEL' | translate }}
    </button>
  </mat-dialog-actions>
</form>
