<div class="dialog-container">
  <h2 mat-dialog-title>
    {{ 'RFID_LIMIT_REACHED_TITLE' | translate }}
  </h2>
  <div mat-dialog-content class="dialog-content">
    <p>{{ 'RFID_LIMIT_REACHED' | translate }}</p>
    <p>{{ 'RFID_ADD_CONFIRM' | translate }}</p>
  </div>
  <mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="dialogRef.close(true)">
      {{ 'ADD' | translate }}
    </button>
    <button mat-raised-button color="warn" class="float-right" type="button" (click)="dialogRef.close(false)">
      {{ 'CANCEL' | translate }}
    </button>
  </mat-dialog-actions>
</div>
