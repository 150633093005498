import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../../core/api';

import { ChargingHistoryResponse } from './types';

@Injectable({
  providedIn: 'root'
})
export class ChargingHistoryService {
  constructor(private readonly apiService: ApiService) {}

  getChargingHistoryByInvoice(invoiceID: number): Observable<ChargingHistoryResponse> {
    return this.apiService.get<ChargingHistoryResponse>(`ChargingHistory/GetChargingHistoryByInvoice/${invoiceID}`);
  }
}
