import { InvoiceFrequencyCompany } from '../invoices';
import { BankAccountType } from '../payments';

export enum CompanyNumberValidationEnum {
  NoValidationApplied = 0, // Validation has not been applied. Company number has not been checked
  ValidationApplied = 1, // Validation process performed. Company number checked
  ValidationSucceeded = 3, // Successful validation. Company number checks out
  Other2 = 4,
  Other3 = 8,
  ValidationFailed = 17, // Failed validation. Issue with company number
  NorwegianCompanyNumberTooShort = 2097, // Company number too short
  NorwegianCompanyNumberTooLong = 4177, // Company number too long
  NorwegianCompanyNumberUnknownFormatError = 145, // Unknwon issue with the format of the Company number
  CompanyNumberMissing = 273, // Company number is not present or is null
  ForeignCompanyWithNoVATNumber = 529, // For foreign companies that are missing a VAT number
  ForeignCompanyMissingInfoName = 1041, // For foreign companies that are missing their info,
  // in this case the name of the company
  CompanyNumberTooShort = 2065, // Generic company number too short
  CompanyNumberTooLong = 4113, // Generic company number too long
  CompanyNumberWrongFormat = 8209, // Generic company number wrong format
  CompanyNumberModulo11FailedDenmark = 16401, // The modulo 11 validation failed in denmark
  CompanyNumberUnknownFormatError = 32785 // Unknown error
}

export type CompanyNumberValidation = keyof typeof CompanyNumberValidationEnum;

export enum EInvoicingProviderEnum {
  Unknown = -1,
  PEPPOL = 20,
  ITELLA = 0,
  TIETOSE = 1,
  LOGICA = 2,
  PROCEEDO = 3,
  HUSERA = 4,
  BASWARE = 5,
  EDB = 6,
  STRALFORS1 = 7,
  LIAISON_FI = 8,
  EXPERT = 9,
  ESSESESS = 10,
  HANDSESS = 11,
  DABASESS = 12,
  SWEDSESS = 13,
  NDEASESS = 14,
  INEXCHANGE = 15,
  SCANCLOUD = 17,
  PAGERO = 18,
  CREDIFLOW = 19,
  COMPELLO = 21,
  LOGIQ = 22,
  APIX = 23,
  AKSESSPUNKT = 24,
  FININVOICE = 25
}

export type EInvoicingProvider = keyof typeof EInvoicingProviderEnum;

export interface Company {
  Address: string;
  ApprovedByAdmin: boolean;
  ApprovedByCustomer: boolean;
  AttnCustomerName: string;
  BankAccountNumber?: string;
  BankAccountNumberType: BankAccountType;
  BIC_SWIFT?: string;
  City?: string;
  CompanyNumber?: string;
  CompanyValidationStatus: CompanyNumberValidation;
  Country: string;
  CreatedByUserID: number;
  CreatedOn: Date;
  Currency: string;
  DateApprovedByAdmin?: Date;
  DateApprovedByCustomer?: Date;
  EInvoicingAddress?: string;
  EInvoicingProvider?: EInvoicingProvider;
  Email: string;
  FK_attn_CustomerID?: number;
  FK_BaseID: number;
  FK_CompanyIDPaying?: number;
  FK_CountryID: number;
  FK_InstallerID?: number;
  FK_InvoiceCountryID: number;
  FK_OperatorID?: number;
  FK_our_contact_CustomerID?: number;
  FK_their_contact_CustomerID?: number;
  HasVATBeenApproved: boolean;
  IBAN?: string;
  InvoiceAddress?: string;
  InvoiceAddress2?: string;
  InvoiceCity?: string;
  InvoiceCountry: string;
  InvoiceEmail?: string;
  InvoiceFrequency: InvoiceFrequencyCompany;
  InvoiceName?: string;
  InvoicePostCode?: string;
  InvoiceReference?: string;
  InvoiceReferencePayouts?: string;
  InvoiceStateOrProvince?: string;
  InvoicingLanguage: string;
  isActive: boolean;
  IsCompany: boolean;
  Keys: string;
  ModifiedByUserID: number;
  Name: string;
  NorwegianAccountNumber?: string;
  OurContactCustomerName: string;
  OwnedByCustomerID: number;
  OwnerEmail: string;
  OwnerFirstName: string;
  OwnerLastName: string;
  OwnerMiddleName: string;
  PayoutsDelayInDays: number;
  Phone: string;
  PK_CompanyID: number;
  PostCode?: string;
  StateOrProvince?: string;
  TheirContactCustomerName: string;
  TimezoneID: string;
  VATNumber?: string;
}
