import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { camelCase } from 'lodash';
import { first } from 'rxjs';

const compareFns = {
  same: (c1: FormControl, c2: FormControl): boolean => c1.value !== c2.value,
  different: (c1: FormControl, c2: FormControl): boolean => c1.value === c2.value
};

export function compareWith(controlName: string, mustBe: keyof typeof compareFns = 'same'): ValidatorFn {
  const compareFn = compareFns[mustBe];

  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.parent) {
      return null;
    }

    const controlToCompare = control.parent.get(controlName) as FormControl;

    controlToCompare.valueChanges.pipe(first()).subscribe(() => {
      control.updateValueAndValidity();
    });

    const result = compareFn(control as FormControl, controlToCompare);

    if (result) {
      const errorKey = camelCase(`not ${mustBe}`);

      return { [errorKey]: true };
    }

    return null;
  };
}
