import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, from, map, of, switchMap } from 'rxjs';

import { ApiService } from '../../core/api';
import { ConfigService } from '../../core/config';

import { HelpdeskLink, HelpdeskTopic } from './types';

@Injectable({
  providedIn: 'root'
})
export class HelpdeskService {
  private readonly dictionary$ = new ReplaySubject<Record<HelpdeskTopic, HelpdeskLink>>();

  constructor(
    private readonly apiService: ApiService,
    private readonly configService: ConfigService
  ) {
    this.loadDictionary();
  }

  deleteLink(link: HelpdeskLink): Observable<never> {
    return this.apiService.delete<never>(`Helpdesk/${link.TopicID}/${link.Language}/${link.Token}`);
  }

  getHelpdeskURL(topic: HelpdeskTopic): Observable<string> {
    return this.dictionary$.pipe(map((dict) => dict[topic]?.Link));
  }

  getLink(
    topic: HelpdeskTopic,
    language?: string,
    token: string = this.configService.get('appToken')
  ): Observable<HelpdeskLink> {
    return this.getLang(language).pipe(
      switchMap((lang) => this.apiService.getUnsecure<HelpdeskLink>(`Helpdesk/${topic}/${lang}/${token}`))
    );
  }

  getLinks(token: string = this.configService.get('appToken')): Observable<HelpdeskLink[]> {
    return this.apiService.get<HelpdeskLink[]>(`Helpdesk/page/0/${token}`);
  }

  getLinksByWhitelabelID(
    token: string = this.configService.get('appToken'),
    language?: string
  ): Observable<HelpdeskLink[]> {
    return this.getLang(language).pipe(
      switchMap((lang) => this.apiService.getUnsecure<HelpdeskLink[]>(`Helpdesk/whitelabel/${token}/${lang}`))
    );
  }

  saveLink(link: HelpdeskLink): Observable<HelpdeskLink> {
    return this.apiService.put<HelpdeskLink>(`Helpdesk/${link.TopicID}/${link.Language}/${link.Token}`, {
      Link: link.Link
    });
  }

  private getLang(lang?: string): Observable<string> {
    return lang ? of(lang) : from(this.configService.getLanguage());
  }

  private loadDictionary(): void {
    this.getLinksByWhitelabelID().subscribe((links: HelpdeskLink[]) =>
      this.dictionary$.next(
        links.reduce((acc, link) => ({ ...acc, [link.TopicID]: link }), {} as Record<HelpdeskTopic, HelpdeskLink>)
      )
    );
  }
}
