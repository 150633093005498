import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CommandsService, GenericBoxUpdate } from 'src/app/lib/commands';
import { DialogService } from '../dialog.service';
import { UserType } from 'src/app/user-roles';
import { CustomValidators } from '@smartcharge/shared/index';

@Component({
  selector: 'sc-update-firmware',
  templateUrl: './update-firmware.component.html',
  styleUrls: ['./update-firmware.component.scss']
})
export class UpdateFirmwareComponent implements OnInit {
  canAddFirmware: boolean;
  canSchedule: boolean;
  updateFirmwareForm: FormGroup;
  boxUpdates: GenericBoxUpdate[] = [];
  boxModelID: number;
  userType = UserType;
  today = new Date().toISOString();

  constructor(
    public dialogRef: MatDialogRef<UpdateFirmwareComponent>,
    private commandService: CommandsService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.updateFirmwareForm = new FormGroup({
      manualFirmware: new FormControl(false, [Validators.required]),
      PK_UpdateID: new FormControl(null, [Validators.required]),
      URLFirmware: new FormControl(''),
      SigningCertificate: new FormControl(''),
      Signature: new FormControl(''),
      installDate: new FormControl('', [CustomValidators.dateMin(this.today)]),
      retrieveDate: new FormControl('', [CustomValidators.dateMin(this.today)])
    });

    this.updateFirmwareForm.controls.manualFirmware.valueChanges.subscribe((isManual) => {
      if (isManual) {
        this.updateFirmwareForm.controls.URLFirmware.setValidators([Validators.required, CustomValidators.validUrl]);
        this.updateFirmwareForm.controls.PK_UpdateID.setValidators([]);
      } else {
        this.updateFirmwareForm.controls.URLFirmware.setValidators([]);
        this.updateFirmwareForm.controls.PK_UpdateID.setValidators([Validators.required]);
      }
      this.updateFirmwareForm.controls.PK_UpdateID.updateValueAndValidity({
        onlySelf: true
      });
      this.updateFirmwareForm.controls.URLFirmware.updateValueAndValidity({
        onlySelf: true
      });
    });

    if (this.boxModelID) {
      this.commandService.getAvailableFirwareUpdates(this.boxModelID).subscribe({
        next: (res: GenericBoxUpdate[]) => {
          this.boxUpdates = res;
        },
        error: (err) => {
          this.boxUpdates = [];
        }
      });
    }
  }

  handleFormSubmit() {
    if (this.updateFirmwareForm.valid) {
      this.dialogRef.close({ ...this.updateFirmwareForm.value });
    } else {
      console.log(this.updateFirmwareForm);
    }
  }

  displayFn(update: any): string {
    return update?.Link || '';
  }

  addFirmwareDialog() {
    this.dialogService.addFirmwareDialog(this.boxModelID, true).subscribe({
      next: (res: GenericBoxUpdate) => {
        if (res) {
          this.boxUpdates.push(res);
          this.updateFirmwareForm.controls.PK_UpdateID.setValue(res?.PK_UpdateID);
          this.updateFirmwareForm.controls.PK_UpdateID.updateValueAndValidity({
            onlySelf: true
          });
        }
      }
    });
  }
}
