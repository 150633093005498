import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Cacheable } from 'ts-cacheable';

import { ApiService } from '../../core/api';
import { ResponseDatas } from '../../core/api/types';
import { AuthService } from '../../core/auth';

import { SMSAuthenticationRequest, SMSCodeRequest, UserLanguage } from './types';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private readonly apiService: ApiService) {}

  @Cacheable({
    cacheBusterObserver: AuthService.cacheBuster$
  })
  getSupportedLanguages(): Observable<UserLanguage[]> {
    return this.apiService
      .get<ResponseDatas<UserLanguage[]>>('Users/GetSupportedLanguages')
      .pipe(map((result) => result?.datas));
  }

  smsAuth(payload: SMSAuthenticationRequest): Observable<void> {
    return this.apiService.postUnsecure('Users/sms/auth', payload);
  }

  smsCodeRequest(payload: SMSCodeRequest): Observable<void> {
    return this.apiService.postUnsecure('Users/sms/request', payload);
  }
}
