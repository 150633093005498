import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'sc-limit-reached',
  templateUrl: './limit-reached.component.html',
  styleUrl: './limit-reached.component.scss'
})
export class LimitReachedComponent {
  constructor(public dialogRef: MatDialogRef<LimitReachedComponent>) {}
}
