export interface HelpdeskLink {
  Language: string;
  Link: string;
  Token?: string;
  Topic?: string;
  TopicID: HelpdeskTopic;
}

export enum HelpdeskTopic {
  // Range 1-99
  // Doesn't-fit-elsewhere topics
  Home = 1,
  // Range 1xx
  // Page-based help
  Financials = 100,
  // Range 2xx
  // Box related topics
  Offline = 200,
  // Range 3xx
  // User related topics
  UnpaidInvoices = 300
}
