import { Injectable } from '@angular/core';

import { StorageService } from '../storage';

import { Environment } from './types';

const URLs: Record<Environment, string> = {
  [Environment.Debug]: 'smartchargeapipd.azurewebsites.net',
  [Environment.QA]: 'meshcrafts-demo-api-app-service-qa.azurewebsites.net',
  [Environment.Production]: 'api.smartcharge.io',
  [Environment.Sandbox]: 'current-api-sandbox.azurewebsites.net',
  [Environment.Staging]: 'meshcrafts-demo-api-app-service-stagging.azurewebsites.net'
};

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  private environment: Environment = Environment.Production;
  private production = true;

  constructor(private readonly storage: StorageService) {}

  get apiURL(): string {
    return `https://${this.baseURL}`;
  }

  get baseURL(): string {
    return `${URLs[this.environment]}/v2/`;
  }

  get isProduction(): boolean {
    return this.production;
  }

  get socketURL(): string {
    return `wss://${this.baseURL}`;
  }

  async init(fallback?: Environment): Promise<void> {
    let env = await this.storage.get<Environment>('environment');

    if (!Object.values(Environment).includes(env)) {
      env = fallback || Environment.Production;
    }

    await this.setEnvironment(env);
  }

  async setEnvironment(environment: Environment, production?: boolean): Promise<void> {
    this.environment = environment;
    this.production = production || environment === Environment.Production;

    await this.storage.set('environment', environment);
  }
}
