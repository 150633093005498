import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../../core/api';

import { ChargingBoxModel } from './types';

@Injectable({
  providedIn: 'root'
})
export class ChargingBoxModelsService {
  constructor(private readonly apiService: ApiService) {}

  getChargingBoxModelsICanAdd(includeRecent = false): Observable<ChargingBoxModel[]> {
    return this.apiService.get<ChargingBoxModel[]>(`ChargingBoxModels/GetChargingBoxModelsICanAdd/${includeRecent}`);
  }
}
