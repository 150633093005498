import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import dayjs from 'dayjs';

class DateValidation {
  constructor(private readonly validationDate: string) {}

  getValidationDate(): string {
    return dayjs(this.validationDate).format('DD-MM-YYYY HH:mm:ss');
  }

  isAfter(control: AbstractControl): boolean {
    try {
      return this.getControlDate(control).isAfter(this.validationDate);
    } catch (_error) {
      return false;
    }
  }

  isBefore(control: AbstractControl): boolean {
    try {
      return this.getControlDate(control).isBefore(this.validationDate);
    } catch (_error) {
      return false;
    }
  }

  private getControlDate(control: AbstractControl): dayjs.Dayjs {
    if (!control.value) {
      throw new Error('Control value is null');
    }

    const controlDate = dayjs(control.value);

    if (!controlDate.isValid()) {
      throw new Error('Control value is not a valid date');
    }

    return controlDate;
  }
}

export function dateMax(date: string): ValidatorFn {
  const dateValidation = new DateValidation(date);

  return (control: AbstractControl): ValidationErrors | null => {
    return dateValidation.isAfter(control) ? { dateMax: { date: dateValidation.getValidationDate() } } : null;
  };
}

export function dateMin(date: string): ValidatorFn {
  const dateValidation = new DateValidation(date);

  return (control: AbstractControl): ValidationErrors | null => {
    return dateValidation.isBefore(control) ? { dateMin: { date: dateValidation.getValidationDate() } } : null;
  };
}

export const dateValid = (control: AbstractControl): ValidationErrors | null => {
  if (!control.value) {
    return null;
  }

  const date = dayjs(control.value);

  return date.isValid() ? null : { dateInvalid: true };
};
