import { Injectable } from '@angular/core';
import { Observable, forkJoin, map, of, throwError } from 'rxjs';
import { Cacheable } from 'ts-cacheable';

import { ApiService } from '../../core/api';
import { ResponseDatas } from '../../core/api/types';
import { GeolocationCoords, GeolocationService } from '../geolocation';

import { Country, CountryPhoneConfig } from './types';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {
  constructor(
    private readonly apiService: ApiService,
    private readonly geolocationService: GeolocationService
  ) {}

  @Cacheable()
  getAll(): Observable<Country[]> {
    return this.apiService.get<ResponseDatas<Country[]>>('Countries/GetAll').pipe(map((result) => result?.datas));
  }

  getCountriesWithSMSSupport(): Observable<CountryPhoneConfig[]> {
    // TODO: Move to API
    return of([
      {
        CountryCode: 47,
        CountryID: 1,
        Name: 'Norway',
        PhoneNumberFormat: '000 00 000'
      },
      {
        CountryCode: 46,
        CountryID: 210,
        Name: 'Sweden',
        PhoneNumberFormat: '000 000 000'
      }
    ]);
  }

  getCountry(position: GeolocationCoords): Observable<Country | undefined> {
    return forkJoin({
      addresses: this.geolocationService.lookupAddress(position),
      countries: this.getAll()
    }).pipe(
      map(({ addresses, countries }) => {
        const countryNames = addresses
          .map(
            (address) => address.address_components.find((component) => component.types.includes('country'))?.short_name
          )
          .filter((value) => value);

        const country = countries.find(({ alpha2CountryCode }) => countryNames.includes(alpha2CountryCode));

        if (!country) {
          throwError(() => new Error('countryNotFound'));
        }

        return country;
      })
    );
  }
}
