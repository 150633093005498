import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, mergeMap, throwError } from 'rxjs';

import { AuthService } from '../auth';
import { EnvironmentService } from '../environment';

import { APIError } from './error';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private readonly authService: AuthService,
    private readonly environmentService: EnvironmentService,
    private readonly http: HttpClient
  ) {}

  delete<T>(url: string, options = {}): Observable<T> {
    return this.authenticationWrapper(() => this.deleteUnsecure(url, options));
  }

  deleteUnsecure<T>(url: string, options = {}): Observable<T> {
    return this.http
      .delete(this.environmentService.apiURL + url, {
        observe: 'response',
        ...options
      })
      .pipe(
        map((res) => ('body' in res ? this.extractData<T>(res) : (res as T))),
        catchError((error) => this.handleError(error))
      );
  }

  get<T>(url: string, options = {}): Observable<T> {
    return this.authenticationWrapper(() => this.getUnsecure<T>(url, options));
  }

  getUnsecure<T>(url: string, options = {}): Observable<T> {
    return this.http
      .get(this.environmentService.apiURL + url, {
        observe: 'response',
        ...options
      })
      .pipe(
        map((res) => ('body' in res ? this.extractData<T>(res) : (res as T))),
        catchError((error) => this.handleError(error))
      );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  patch<T>(url: string, body: any, options = {}): Observable<T> {
    return this.authenticationWrapper(() => this.patchUnsecure<T>(url, body, options));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  patchUnsecure<T>(url: string, body: any, options = {}): Observable<T> {
    return this.http
      .patch(this.environmentService.apiURL + url, body, {
        observe: 'response',
        ...options
      })
      .pipe(
        map((res) => this.extractData<T>(res)),
        catchError((error) => this.handleError(error))
      );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  post<T>(url: string, body: any, options = {}): Observable<T> {
    return this.authenticationWrapper(() => this.postUnsecure<T>(url, body, options));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  postUnsecure<T>(url: string, body: any, options = {}): Observable<T> {
    return this.http
      .post(this.environmentService.apiURL + url, body, {
        observe: 'response',
        ...options
      })
      .pipe(
        map((res) => this.extractData<T>(res)),
        catchError((error) => this.handleError(error))
      );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  put<T>(url: string, body?: any, options = {}): Observable<T> {
    return this.authenticationWrapper(() => this.putUnsecure(url, body, options));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  putUnsecure<T>(url: string, body: any, options = {}): Observable<T> {
    return this.http
      .put(this.environmentService.apiURL + url, body, {
        observe: 'response',
        ...options
      })
      .pipe(
        map((res) => this.extractData<T>(res)),
        catchError((error) => this.handleError(error))
      );
  }

  private authenticationWrapper<T>(requestFn: () => Observable<T>): Observable<T> {
    return this.authService.authenticate().pipe(
      mergeMap((authenticated) => {
        if (authenticated) {
          return requestFn();
        } else {
          return throwError(() => new Error('Unable to re-authenticate'));
        }
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private extractData<T>(res: HttpResponse<any> | HttpErrorResponse): T {
    if (res instanceof HttpErrorResponse || res.status !== 200) {
      throw new APIError(res as HttpErrorResponse);
    }

    return res.body?.Result || res.body;
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    return throwError(() => new APIError(error));
  }
}
