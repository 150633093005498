import { Injectable } from '@angular/core';
import { Observable, Subject, catchError, from, map, of } from 'rxjs';

import { TokenService } from './token';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  static readonly cacheBuster$ = new Subject<void>();

  constructor(private readonly tokenService: TokenService) {}

  authenticate(): Observable<boolean> {
    if (this.tokenService.token) {
      return of(true);
    }

    return from(this.tokenService.refreshToken()).pipe(
      map((token: string) => !!token),
      catchError(() => of(false))
    );
  }
}
