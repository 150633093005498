import { compareWith } from './compare-with';
import { dateMax, dateMin, dateValid } from './date';
import { email } from './email';
import { validUrl } from './valid-url';

export * from './address';

export const CustomValidators = {
  compareWith,
  dateMax,
  dateMin,
  dateValid,
  email,
  validUrl
};
