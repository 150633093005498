import { Injectable } from '@angular/core';
import { map, Observable, timeout } from 'rxjs';
import { Cacheable } from 'ts-cacheable';

import { ApiService } from '../../core/api';
import { ResponseDatas } from '../../core/api/types';
import { IPLocation } from '../geolocation';
import { TimezoneInfo } from '../users';

import { UserPreferences } from './types';

@Injectable({
  providedIn: 'root'
})
export class UserPrefrencesService {
  constructor(private readonly apiService: ApiService) {}

  @Cacheable({
    maxAge: 30_000
  })
  getIP(timeoutAfter = 3_000): Observable<IPLocation> {
    return this.apiService.getUnsecure<ResponseDatas<IPLocation>>('UserPreferences/GetIP').pipe(
      timeout({ first: timeoutAfter }),
      map((result) => result?.datas)
    );
  }

  getTimezones(): Observable<TimezoneInfo[]> {
    return this.apiService.get<TimezoneInfo[]>('UserPreferences/GetTimezones');
  }

  getUserPreferences(userID: number): Observable<UserPreferences> {
    return this.apiService.get<UserPreferences>(`UserPreferences/GetUserPreferences/${userID}`);
  }
}
