import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';

import { APIResponse, ResponseDatas } from '../api/types';
import { APP_TYPE, AppType } from '../config';
import { EnvironmentService } from '../environment';
import { StorageService } from '../storage';

const helper = new JwtHelperService();

type RefreshAccessToken = ResponseDatas<string>;

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private _token?: string;
  private tokenRefreshing?: Promise<string>;

  constructor(
    @Inject(APP_TYPE) private readonly appType: AppType,
    private readonly environmentService: EnvironmentService,
    private readonly http: HttpClient,
    private readonly storageService: StorageService
  ) {}

  get token(): string | undefined {
    if (this._token && helper.isTokenExpired(this._token)) {
      this._token = undefined;
    }

    return this._token;
  }

  refreshToken(reload?: boolean): Promise<string> {
    if (this.tokenRefreshing) {
      return this.tokenRefreshing;
    }

    this.tokenRefreshing = new Promise(async (resolve, reject) => {
      const token = await this.storageService.get('token');

      if (!reload && token && !helper.isTokenExpired(token)) {
        this._token = token;

        return resolve(token);
      }

      const rToken = await this.storageService.get('rToken');

      if (!rToken) {
        return reject(new Error('User not authenticated'));
      }

      // App and portal are using different keys for appID
      // @TODO: Fix storage key in the app
      let request: Observable<APIResponse<RefreshAccessToken>>;

      if (this.appType === 'app') {
        const appID = await this.storageService.get('appid');
        const pushToken = await this.storageService.get('pushToken');

        request = this.http.post<APIResponse<RefreshAccessToken>>(
          `${this.environmentService.apiURL}Security/RefreshAccessTokenInternal`,
          { appID, pushToken, rToken }
        );
      } else {
        const appID = await this.storageService.get('appID');

        request = this.http.post<APIResponse<RefreshAccessToken>>(
          `${this.environmentService.apiURL}Security/RefreshAccessTokenDashboard?n=${Date.now()}`,
          { appID, rToken }
        );
      }

      request.subscribe({
        next: (res) => {
          const tokenInternal = res?.Result?.datas;

          if (!tokenInternal) {
            this.storageService.remove('rToken');

            return reject(new Error('Could not refresh token'));
          } else if (helper.isTokenExpired(tokenInternal)) {
            return reject(new Error('Token has expired'));
          }

          this.storageService.set('token', tokenInternal);
          this._token = tokenInternal;

          return resolve(tokenInternal);
        },
        error: (err: HttpErrorResponse) => {
          reject(err.message);
        }
      });
    });

    this.tokenRefreshing.finally(() => {
      this.tokenRefreshing = undefined;
    });

    return this.tokenRefreshing;
  }
}
