import { Directive, Self, HostListener } from '@angular/core';
import { ControlContainer } from '@angular/forms';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'form[formGroup]'
})
export class FormMarkTouchedDirective {
  constructor(@Self() private readonly container: ControlContainer) {}

  @HostListener('submit') onSubmit(): void {
    if (this.container.control) {
      this.container.control.markAllAsTouched();
      this.container.control.updateValueAndValidity({ emitEvent: true });
    }
  }
}
