import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../../core/api';

import { ChangePasswordRequest, UpdateFirmwareRequest } from './types';

@Injectable({
  providedIn: 'root'
})
export class CommandsService {
  constructor(private readonly apiService: ApiService) {}

  changePassword(request: ChangePasswordRequest): Observable<void> {
    return this.apiService.post('Commands/ChangePassword', request);
  }

  updateToFirmware(boxID: number, request: UpdateFirmwareRequest): Observable<void> {
    return this.apiService.post(`Commands/UpdateToFirmware/${boxID}`, request);
  }
}
