/// <reference types="@types/google.maps" />

export type GeocoderResponse = google.maps.GeocoderResponse;
export type GeocoderResult = google.maps.GeocoderResult;

export interface Address {
  city: string;
  country?: string;
  postcode?: string;
  state?: string;
  street: string;
  streetNumber?: string;
}

export function addressToString(address: Address): string {
  const segments = [
    [address.street, address.streetNumber],
    [address.postcode, address.city],
    address.state,
    address.country
  ]
    .map((values) => {
      if (Array.isArray(values)) {
        return values.filter((value) => typeof value === 'string' && value.length).join(' ');
      }

      return values;
    })
    .filter((value) => typeof value === 'string' && value.length);

  return segments.join(', ');
}

export interface GeolocationCoords {
  latitude: number;
  longitude: number;
}

export interface IPLocation {
  city: string;
  connection: {
    asn: number;
    isp: string;
  };
  connection_type: string;
  continent_code: string;
  continent_name: string;
  country_code: string;
  country_name: string;
  currency: {
    code: string;
    name: string;
    plural: string;
    symbol: string;
    symbol_native: string;
  };
  dma: null;
  ip: string;
  ip_routing_type: string;
  latitude: number;
  location: {
    calling_code: string;
    capital: string;
    country_flag: string;
    country_flag_emoji: string;
    country_flag_emoji_unicode: string;
    geoname_id: number;
    is_eu: boolean;
    languages: {
      code: string;
      name: string;
      native: string;
    }[];
  };
  longitude: number;
  msa: null;
  radius: string;
  region_code: string;
  region_name: string;
  time_zone: {
    code: string;
    current_time: string;
    gmt_offset: number;
    id: string;
    is_daylight_saving: boolean;
  };
  type: string;
  zip: string;
}
