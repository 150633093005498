import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { ApiService } from '../../core/api';
import { ResponseDatas } from '../../core/api/types';

import { Company } from './types';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  constructor(private readonly apiService: ApiService) {}

  getCompaniesForOperator(): Observable<Company[]> {
    return this.apiService
      .get<ResponseDatas<Company[]>>('Companies/GetCompaniesForOperator')
      .pipe(map((result) => result?.datas));
  }
}
