import { Injectable } from '@angular/core';
import { HttpInterceptorService } from 'src/app/services/http-interceptor/http-interceptor.service';
import { User } from './user.model';
import { Observable, take } from 'rxjs';
import { UserFacade } from '../state/user/user.facade';
import { AES } from 'jscrypto/es6/AES';
import { UserPreferences } from './user-preferences.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private _http: HttpInterceptorService,
    private userFacade: UserFacade
  ) {}

  getUser(): Observable<User> {
    return this.userFacade.user$.pipe(take(1));
  }

  getUserPreferences(): Observable<UserPreferences> {
    return this.userFacade.userPreferences$.pipe(take(1));
  }

  getCustomerByID(customerID: number) {
    return this._http.get(`Customers/${customerID}`);
  }

  updateUser(user: User): Observable<User> {
    return this._http.put<User>('Customers', user);
  }

  storeUpdateUser(user: User) {
    user && localStorage.setItem('user', JSON.stringify(user));
    return this.userFacade.userUpdated(user);
  }

  storeUpdatedPreferences(preferences: UserPreferences) {
    preferences && localStorage.setItem('userPreferences', JSON.stringify(preferences));
    return this.userFacade.preferencesUpdated(preferences);
  }

  changePassword(user: User, password: { Old: string; New: string }) {
    const payload = {
      PK_UserID: user.FK_UserID,
      OldPassword: password.Old,
      NewPassword: password.New
    };
    return this._http.post(`Users/ChangePassword`, payload);
  }

  clearUser(sendLogoutRequest: boolean = true) {
    if (sendLogoutRequest) {
      this.userFacade.logOutUser();
    }

    localStorage.removeItem('token');
    localStorage.removeItem('rToken');
    localStorage.removeItem('user');
    localStorage.removeItem('appID');
    localStorage.removeItem('type');
  }

  storeUser(
    user?: User,
    token?: string,
    rToken?: string,
    appID?: string,
    type?: string,
    permissions?: string,
    userCompanies?: number[],
    userOperators?: number[],
    userInstallers?: number[],
    userPreferences?: UserPreferences
  ) {
    token && localStorage.setItem('token', token);
    rToken && localStorage.setItem('rToken', rToken);
    user && localStorage.setItem('user', JSON.stringify(user));
    appID && localStorage.setItem('appID', appID);
    type && localStorage.setItem('type', type);
    userCompanies && localStorage.setItem('userCompanies', userCompanies.toString());
    userOperators && localStorage.setItem('userOperators', userOperators.toString());
    userInstallers && localStorage.setItem('userInstallers', userInstallers.toString());
    userPreferences && localStorage.setItem('userPreferences', JSON.stringify(userPreferences));
    if (permissions) {
      const permission = AES.encrypt(JSON.stringify(permissions), rToken);
      localStorage.setItem('ac', permission.toString());
    } else {
      const permission = AES.encrypt(JSON.stringify([]), rToken);
      localStorage.setItem('ac', permission.toString());
    }

    this.userFacade.loadUser(user, userPreferences);
  }

  getCustomerID(): number {
    const token = localStorage.getItem('user');
    const json = JSON.parse(token);

    return json['PK_CustomerID'];
  }

  storePermissions(permissions: any[], rToken: string) {
    const encripted = AES.encrypt(JSON.stringify(permissions), rToken);
    localStorage.setItem('ac', encripted.toString());
  }

  logoutUser() {
    const userKeys = JSON.parse(localStorage.getItem('user'))?.UserKeys;
    const appId = localStorage.getItem('appID');
    return this._http.get(`Users/Logout/${userKeys}/${appId}`);
  }

  getLocalUser() {
    return this.userFacade.getUserFromLocal();
  }

  getSupportedLanguages() {
    return this._http.get('Users/GetSupportedLanguages');
  }

  putUserPreferences(preferences: UserPreferences) {
    return this._http.put('UserPreferences', preferences);
  }

  fetchUserPreferences(userID: number) {
    return this._http.get(`UserPreferences/GetUserPreferences/${userID}`);
  }
}
