import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';

import { ApiService } from '../../core/api';
import { ResponseDatas } from '../../core/api/types';

import { Invoice, PayInvoiceResult } from './types';

@Injectable({
  providedIn: 'root'
})
export class InvoicesService {
  constructor(private readonly apiService: ApiService) {}

  @Cacheable({
    storageStrategy: LocalStorageStrategy
  })
  getInvoiceByID(invoiceID: number): Observable<Invoice> {
    return this.apiService.get<Invoice>(`Invoices/InvoiceByID/${invoiceID}`);
  }

  getInvoicesByCustomer(
    customerID: number,
    paidInvoices = true,
    unpaidInvoices = true,
    number = 20,
    startIndex = 0,
    sendCount = false
  ): Observable<Invoice[]> {
    return this.apiService
      .get<
        ResponseDatas<Invoice[]>
      >(`Invoices/GetInvoicesByCustomer/${customerID}/${paidInvoices}/${unpaidInvoices}/${number}/${startIndex}/${sendCount}`)
      .pipe(map((result) => result?.datas));
  }

  payInvoice(invoiceID: number): Observable<PayInvoiceResult> {
    return this.apiService.get<PayInvoiceResult>(`Invoices/PayInvoice/${invoiceID}`).pipe(
      map(() => ({ invoiceID, status: 'success' }) as PayInvoiceResult),
      catchError((err) => of({ invoiceID, message: err.message, status: 'error' } as PayInvoiceResult))
    );
  }
}
