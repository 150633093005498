import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { ApiService } from '../../core/api';
import { ResponseDatas } from '../../core/api/types';
import { ProductClient } from '../product-templates';

import { ActivatedFeature, Feature, FeatureType } from './types';

@Injectable({
  providedIn: 'root'
})
export class FeaturesService {
  constructor(private readonly apiService: ApiService) {}

  activate(companyID: number, featureID: number, payload: ProductClient): Observable<ActivatedFeature> {
    return this.apiService.post(`features/companies/${companyID}/activate/${featureID}`, payload);
  }

  deactivate(companyID: number, featureID: number): Observable<Feature> {
    return this.apiService.patch<Feature>(`features/companies/${companyID}/deactivate/${featureID}`, {});
  }

  findActiveCompanyFeature(companyID: number, featureType: FeatureType): Observable<ActivatedFeature | undefined> {
    return this.getCompanyFeatures(companyID).pipe(
      map((result) => {
        const activeFeature = result?.find((feature) => feature.FeatureType === featureType && feature.IsActivated);

        return activeFeature ? (activeFeature as ActivatedFeature) : undefined;
      })
    );
  }

  getCompanyFeatures(companyID: number): Observable<Feature[]> {
    return this.apiService
      .get<ResponseDatas<Feature[]>>(`features/companies/${companyID}`)
      .pipe(map((result) => result?.datas));
  }

  update(companyID: number, featureID: number, payload: ProductClient): Observable<ActivatedFeature> {
    return this.apiService.patch(`features/companies/${companyID}/update/${featureID}`, payload);
  }
}
