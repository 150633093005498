import { FormArray, FormControl, FormGroup } from '@angular/forms';

/**
 * Returns flat list of controls from nested `FormGroup` based on condition.
 *
 * @export
 * @param {FormGroup} form
 * @param {(keyof FormControl | ((control: FormControl) => boolean))} condition  Property of `FormControl`
 *  or function to filter controls out.
 * @return {*}  {FormControl[]}
 */
export function findFormControls(
  form: FormGroup | FormArray,
  condition: keyof FormControl | ((control: FormControl) => boolean)
): FormControl[] {
  const controls = Object.values(form.controls).reduce((acc, control) => {
    if (control instanceof FormGroup || control instanceof FormArray) {
      acc.push(...findFormControls(control, condition));
    } else if (control instanceof FormControl) {
      if (
        (typeof condition === 'function' && condition(control)) ||
        (typeof condition === 'string' && control[condition])
      ) {
        acc.push(control);
      }
    }

    return acc;
  }, [] as FormControl[]);

  return controls;
}
