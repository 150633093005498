import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { UserPrefrencesService } from '../user-preferences';

import { Address, addressToString, GeocoderResponse, GeocoderResult, GeolocationCoords, IPLocation } from './types';

const GOOGLE_MAPS_API_KEY = 'AIzaSyBNAJJ0c9-VScOE6H78bO-pWqKBAwqZg7E';

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {
  constructor(
    private readonly http: HttpClient,
    private readonly userPreferencesService: UserPrefrencesService
  ) {}

  getLocationFromIP(): Observable<GeolocationCoords> {
    return this.userPreferencesService
      .getIP()
      .pipe(map(({ latitude, longitude }: IPLocation) => ({ latitude, longitude })));
  }

  lookupAddress(location: GeolocationCoords): Observable<GeocoderResult[]> {
    return this.http
      .get<GeocoderResponse>(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.latitude},${location.longitude}&key=${GOOGLE_MAPS_API_KEY}`
      )
      .pipe(map((result) => result?.results));
  }

  validateAddress(address: string | Address): Observable<GeocoderResponse> {
    const query = typeof address === 'string' ? address : addressToString(address);

    return this.http.get<GeocoderResponse>(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${query}&key=${GOOGLE_MAPS_API_KEY}`
    );
  }
}
