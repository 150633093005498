import { HttpErrorResponse } from '@angular/common/http';

export class APIError implements Error {
  message: string;
  name: string;
  stack?: string | undefined;

  constructor(error: HttpErrorResponse) {
    this.name = error.url || 'APIError';
    this.message = error.error?.Message || error.error?.datas?.Message || error.statusText;
  }

  get errorName(): string {
    return this.message;
  }
}
