import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { ApiService } from '../../core/api';
import { ResponseDatas } from '../../core/api/types';
import { FeatureDetailsHomeCharging, FeatureDetailsHomeChargingLocation } from '../features';

import { HomeChargingAddUsersResult, HomeChargingEmployee, HomeChargingRemoveUserPayload } from './types';

@Injectable({
  providedIn: 'root'
})
export class HomeChargingService {
  constructor(private readonly apiService: ApiService) {}

  addEmployees(featureClientID: number, employees: HomeChargingEmployee[]): Observable<HomeChargingAddUsersResult> {
    return this.apiService
      .post<ResponseDatas<HomeChargingAddUsersResult>>(`home-charging/${featureClientID}/add`, employees)
      .pipe(map((result) => result?.datas));
  }

  deleteEmployee(
    featureClientID: number,
    location: FeatureDetailsHomeChargingLocation,
    payload: HomeChargingRemoveUserPayload
  ): Observable<void> {
    return this.apiService.delete(`home-charging/${featureClientID}/remove/${location.CompanyUserID}`, {
      body: payload
    });
  }

  updateFeatureDetails(featureDetails: FeatureDetailsHomeCharging): Observable<FeatureDetailsHomeCharging> {
    return this.apiService.put('home-charging', featureDetails);
  }
}
