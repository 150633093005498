<form [formGroup]="updateFirmwareForm" (ngSubmit)="handleFormSubmit()">
  <h2 mat-dialog-title>{{ 'UPDATE_FIRMWARE' | translate }}</h2>

  <mat-dialog-content>
    <div *ngIf="!updateFirmwareForm.controls.manualFirmware.value">
      <mat-form-field class="w-full" floatLabel="always">
        <mat-label>{{ 'USE_EXISTING_FIRMWARE' | translate }}</mat-label>
        <mat-select formControlName="PK_UpdateID">
          @for (item of boxUpdates; track item.PK_UpdateID) {
            <mat-option [value]="item?.PK_UpdateID">
              <div class="mb-2">{{ item.FirmwareVersion }}</div>
              <small class="break-all leading-tight text-gray-500">
                {{ item?.Link }}
              </small>
            </mat-option>
          }
        </mat-select>
        @if (canAddFirmware) {
          <button
            matSuffix
            mat-icon-button
            class="toolbar-button ml-1"
            type="button"
            *canSee="[userType.Admin, userType.BoxManufacturer]"
            (click)="$event.stopPropagation(); addFirmwareDialog()">
            <mat-icon>add</mat-icon>
          </button>
        }
        <mat-error>{{ 'REQUIRED' | translate }}</mat-error>
      </mat-form-field>
    </div>
    <mat-slide-toggle formControlName="manualFirmware">
      {{ 'CUSTOM_FIRMWARE_UPDATE' | translate }}
    </mat-slide-toggle>
    <div *ngIf="updateFirmwareForm.controls.manualFirmware.value" class="mt-4">
      <mat-form-field floatLabel="auto" class="w-full">
        <mat-label>{{ 'URL' | translate }}</mat-label>
        <input matInput type="text" formControlName="URLFirmware" />
        <mat-error scError controlName="URLFirmware"></mat-error>
      </mat-form-field>

      <mat-form-field floatLabel="auto" class="w-full">
        <mat-label>{{ 'SIGNING_CERTIFICATE' | translate }}</mat-label>
        <textarea matInput formControlName="SigningCertificate" cols="10" rows="4"></textarea>
        <button
          mat-icon-button
          matSuffix
          class="input-helper"
          type="button"
          matTooltip="{{ 'SIGNING_CERTIFICATE_HELP' | translate }}">
          <mat-icon>help_outline</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field floatLabel="auto" class="w-full">
        <mat-label>{{ 'SIGNATURE' | translate }}</mat-label>
        <textarea matInput formControlName="Signature" cols="10" rows="4"></textarea>
      </mat-form-field>
    </div>
    @if (canSchedule) {
      <div class="mt-4">
        <mat-form-field class="mr-4">
          <mat-label>{{ 'SCHEDULE_INSTALL' | translate }}</mat-label>
          <input matInput type="datetime-local" formControlName="installDate" />
          <mat-hint>UTC</mat-hint>
          <mat-error scError controlName="installDate"></mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'SCHEDULE_DOWNLOAD' | translate }}</mat-label>
          <input matInput type="datetime-local" formControlName="retrieveDate" />
          <mat-hint>UTC</mat-hint>
          <mat-error scError controlName="retrieveDate"></mat-error>
        </mat-form-field>
      </div>
    }
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button color="primary" type="submit">
      {{ 'UPDATE_FIRMWARE' | translate }}
    </button>
    <button mat-raised-button (click)="this.dialogRef.close()" type="button">
      {{ 'CANCEL' | translate }}
    </button>
  </mat-dialog-actions>
</form>
